import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,  // Importa o plugin de cores automáticas
} from 'chart.js';

// Registra os componentes necessários para o gráfico, incluindo o plugin Colors
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors  // Registra o plugin de cores automáticas
);

const Linechart = () => {
    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Dados 1',
                data: [65, 59, 80, 81, 56, 55, 40],
                fill: false,
            },
            {
                label: 'Dados 2',
                data: [12, 32, 64, 21, -19, 88, 50],
                fill: false,
            },
            {
                label: 'Dados 3',
                data: [51, 7, 32, 45, 78, 30, 0],
                fill: false,
            },
        ],
    };

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,  // Permite ajustar o gráfico dentro do container
    };

    return (
        <div style={{ width: '35%', height: '50%', position: 'relative', left: '15%', top: '50%', display: 'flex' }}>  {/* Ajusta o tamanho do gráfico */}
            <Line data={data} options={options} />
        </div>
    );
};

export default Linechart;
