import React, { useState, useEffect } from 'react';
import './Geral.css';
import './Extrato.css';
import './ExtratoCliente.css';
import axios from 'axios';

const Extrato = () => {
  const [extrato, setExtrato] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const itemsPerPage = 20;

  // Obtém o ID da URL da página atual
  const urlParams = new URLSearchParams(window.location.search);
  const mem_id = urlParams.get('id'); // Extrai o ID da URL

  // Função para buscar os dados da API
  const fetchExtrato = async (page = 1, search = '') => {
    const token = localStorage.getItem('token');
    setLoading(true);
    setNoResults(false);

    try {
      const response = await axios.get(
        `https://samp-teste.jelastic.saveincloud.net/backoffice/users/extrato/?id=${mem_id}&page=${page}&pesquisa=${search}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.message === "Nenhum resultado encontrado.") {
        setNoResults(true);
        setExtrato([]);
      } else {
        setExtrato(response.data);
      }
    } catch (error) {
      console.error('Erro ao buscar o extrato:', error);
    } finally {
      setLoading(false);
    }
  };

  // Chama a função para buscar os dados na montagem do componente
  useEffect(() => {
    fetchExtrato(currentPage, searchTerm);
  }, [currentPage]);

  // Função para avançar para a próxima página
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Função para voltar para a página anterior
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
  };

  // Função para lidar com a mudança no campo de pesquisa
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Função para enviar a pesquisa
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    setCurrentPage(1); // Reinicia para a primeira página
    fetchExtrato(1, searchTerm); // Envia a pesquisa
  };

  // Função para lidar com a tecla Enter na barra de pesquisa
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearchSubmit(event);
    }
  };

  return (
    <div className="home-container">
      <header className="home-header">
        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />
        <h4 style={{ position: 'relative', right: '4%' }}></h4>
        <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} />
        <span style={{ position: 'relative', right: '1%', width: '7%' }}> <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a> </span>
      </header>

      <body>
        {/* LISTA DE NAVEGAÇÃO */}
        <ul className='ListaNavegacao'>
          <li className='itemLista1'> <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/home'> Home</a> </li>
          <li className='itemLista2' style={{ backgroundColor: '#75757536' }}> <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href=''> Extrato</a> </li>
          <li className='itemLista3'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href='/clientes'> Clientes</a> </li>
          <li className='itemLista4'> <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} /> <a href=''> Funcionarios</a> </li>
        </ul>

        {/* AREA PRINCIPAL */}
        <div className='ContainerLista'>
          {/* Barra de Pesquisa */}
          <div className="search-bar" style={{ display: 'flex', marginBottom: '20px', }}>
            <input
              type="text"
              placeholder="Pesquisar..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
              className='barraPesquisa'
              style={{ padding: '12px', width: '50%' }}
            />
            <button onClick={handleSearchSubmit} className='botaoPesquisa'>Pesquisar</button>
          </div>

          {/* Tabela de Resultados */}
          <table style={{ width: '80%', borderCollapse: 'collapse', color: 'white' }}>
            <thead>
              <tr>
                <th className='Titulo'>Valor</th>
                <th className='Titulo'>Tipo</th>
                <th className='Titulo'>Data</th>
                <th className='Titulo'>Nome Destino</th>
                <th className='Titulo'>Método</th>
                <th className='Titulo'>Status</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">Carregando...</td>
                </tr>
              ) : noResults ? (
                <tr>
                  <td colSpan="6">Nenhum resultado encontrado.</td>
                </tr>
              ) : (
                extrato.map((item, index) => (
                  <tr key={index}>
                    <td className='Titulo'>{item.valor}</td>
                    <td className='Titulo'>{item.tipo}</td>
                    <td className='Titulo'>{item.data}</td>
                    <td className='Titulo'>{item.nome_destino}</td>
                    <td className='Titulo'>{item.metodo}</td>
                    <td className='Titulo'>{item.status}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>

          {/* Paginação */}
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              &lt;
            </button>
            <span>{currentPage}</span>
            <button onClick={handleNextPage}>
              &gt;
            </button>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Extrato;
