import './Barchart.css'
import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

// Registra os componentes necessários para o gráfico
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

// Função para gerar dados aleatórios dentro de um intervalo
const generateRandomData = (min, max, length) => {
    return Array.from({ length }, () => Math.floor(Math.random() * (max - min + 1)) + min);
};

// Gera as labels das últimas 24 horas
const generate24HourLabels = () => {
    const labels = [];
    for (let i = 0; i < 24; i++) {
        labels.push(i.toString().padStart(2, '0') + ':00');
    }
    return labels;
};


const Barchart = ({ setTotalMovimentacao, setTotalLucro, setTotalDeficit }) => {
    const [timeRange, setTimeRange] = useState('Este Mês');
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    // Função para calcular o total de uma categoria de um dataset
    const calculateTotal = (dataset) => {
        return dataset.reduce((total, value) => total + value, 0);
    };

    // Dados e rótulos baseados no filtro selecionado
    const getDataByTimeRange = () => {
        switch (timeRange) {
            case 'Hoje':
                return {
                    labels: generate24HourLabels(),
                    datasets: [
                        {
                            label: 'Movimentação',
                            data: [20, 30, 25, 40, 50, 30, 45, 60, 70, 50, 30, 20, 40, 50, 60, 70, 80, 90, 40, 30, 20, 30, 40, 50],
                            backgroundColor: 'rgba(115, 164, 255, 0.8)',
                        },
                        {
                            label: 'Lucro',
                            data: [5, 10, 7, 15, 10, 5, 12, 8, 10, 12, 14, 10, 8, 12, 10, 14, 18, 5, 8, 10, 7, 5, 12, 10],
                            backgroundColor: 'rgba(125, 255, 115, 0.8)',
                        },
                        {
                            label: 'Déficit',
                            data: [-5, -10, -7, -5, -10, -5, -7, -8, -5, -7, -10, -12, -8, -5, -7, -10, -14, -5, -10, -12, -5, -10, -7, -5],
                            backgroundColor: 'rgba(237, 71, 71, 0.8)',
                        },
                    ],
                };
            case 'Esta Semana':
                return {
                    labels: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb', 'Dom'],
                    datasets: [
                        {
                            label: 'Movimentação',
                            data: [120, 130, 110, 140, 150, 130, 145],
                            backgroundColor: 'rgba(115, 164, 255, 0.8)',
                        },
                        {
                            label: 'Lucro',
                            data: [25, 30, 20, 35, 40, 20, 25],
                            backgroundColor: 'rgba(125, 255, 115, 0.8)',
                        },
                        {
                            label: 'Déficit',
                            data: [-10, -15, -7, -10, -5, -15, -7],
                            backgroundColor: 'rgba(237, 71, 71, 0.8)',
                        },
                    ],
                };
            case 'Este Mês':
                return {
                    labels: ['Semana 1', 'Semana 2', 'Semana 3', 'Semana 4'],
                    datasets: [
                        {
                            label: 'Movimentação',
                            data: [500, 600, 450, 700],
                            backgroundColor: 'rgba(115, 164, 255, 0.8)',
                        },
                        {
                            label: 'Lucro',
                            data: [100, 150, 120, 180],
                            backgroundColor: 'rgba(125, 255, 115, 0.8)',
                        },
                        {
                            label: 'Déficit',
                            data: [-50, -70, -60, -90],
                            backgroundColor: 'rgba(237, 71, 71, 0.8)',
                        },
                    ],
                };
            case '3 Meses':
                return {
                    labels: ['Mês 1', 'Mês 2', 'Mês 3'],
                    datasets: [
                        {
                            label: 'Movimentação',
                            data: [1500, 1800, 1600],
                            backgroundColor: 'rgba(115, 164, 255, 0.8)',
                        },
                        {
                            label: 'Lucro',
                            data: [300, 350, 320],
                            backgroundColor: 'rgba(125, 255, 115, 0.8)',
                        },
                        {
                            label: 'Déficit',
                            data: [-200, -250, -220],
                            backgroundColor: 'rgba(237, 71, 71, 0.8)',
                        },
                    ],
                };
            case 'Ano':
                return {
                    labels: ['JAN', 'FEV', 'MAR', 'ABR', 'MAI', 'JUN', 'JUL', 'AGO', 'SET', 'OUT', 'NOV', 'DEZ'],
                    datasets: [
                        {
                            label: 'Movimentação',
                            data: [2000, 2100, 2200, 2300, 2400, 2500, 2600, 2700, 2800, 2900, 3000, 3100],
                            backgroundColor: 'rgba(115, 164, 255, 0.8)',
                        },
                        {
                            label: 'Lucro',
                            data: [400, 420, 430, 450, 460, 470, 480, 490, 500, 510, 520, 530],
                            backgroundColor: 'rgba(125, 255, 115, 0.8)',
                        },
                        {
                            label: 'Déficit',
                            data: [-150, -170, -180, -190, -200, -210, -220, -230, -240, -250, -260, -270],
                            backgroundColor: 'rgba(237, 71, 71, 0.8)',
                        },
                    ],
                };
            default:
                return {};
        }
    };

    const data = getDataByTimeRange();

    // Calcular os totais para cada categoria e enviá-los para o componente pai (Home.js)
    useEffect(() => {
        if (data.datasets) {
            setTotalMovimentacao(calculateTotal(data.datasets[0].data));
            setTotalLucro(calculateTotal(data.datasets[1].data));
            setTotalDeficit(calculateTotal(data.datasets[2].data));
        }
    }, [timeRange, data, setTotalMovimentacao, setTotalLucro, setTotalDeficit]);

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        maintainAspectRatio: false,
    };

    return (
        <div style={{ margin: '0 auto', textAlign: 'center', position: 'relative', left: '0%'}}>
                <button style={{position: 'relative', left: '-48.2%',cursor: 'pointer',}} className='botaoFiltro' onClick={() => setIsFilterOpen(!isFilterOpen)}>
                    <img src='./imagens/config-icon.png' width={30}/>
                </button>
                {isFilterOpen && (
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: '1.5%',
                        left: '33.5%',
                        width: '32%',
                        backgroundColor: '#00000050',
                        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
                        zIndex: 1,
                        borderRadius: '5px',
                    }}>
                    <div onClick={() => { setTimeRange('Hoje'); setIsFilterOpen(false); }} style={{ padding: '10px 0', margin: '0 10px', cursor: 'pointer' }}>Hoje</div>
                        <hr style={{borderColor: '#000', marginBlock: 5}}/>
                        <div onClick={() => { setTimeRange('Esta Semana'); setIsFilterOpen(false); }} style={{ padding: '10px 0', margin: '0 10px', cursor: 'pointer' }}> Semana</div>
                        <hr style={{borderColor: '#000', marginBlock: 5}}/>
                        <div onClick={() => { setTimeRange('Este Mês'); setIsFilterOpen(false); }} style={{ padding: '10px 0', margin: '0 10px', cursor: 'pointer' }}> Mês</div>
                        <hr style={{borderColor: '#000', marginBlock: 5}}/>
                        <div onClick={() => { setTimeRange('3 Meses'); setIsFilterOpen(false); }} style={{ padding: '10px 0', margin: '0 10px', cursor: 'pointer' }}>3 Meses</div>
                        <hr style={{borderColor: '#000', marginBlock: 5}}/> 
                        <div onClick={() => { setTimeRange('Ano'); setIsFilterOpen(false); }} style={{ padding: '10px 0', margin: '0 10px', cursor: 'pointer' }}>Ano</div>
                    </div>
                )}
            <div style={{ width: 1000, height: '400px' }}>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

export default Barchart;
