// src/Clientes.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Geral.css';
import './Clientes.css';

const Clientes = () => {
  const [clientes, setClientes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  // Funções para lidar com a paginação
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = clientes.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(clientes.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleRedirect = (mem_id) => {
    window.location.href = `/extratoCliente?id=${mem_id}`;
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const fetchClientes = async () => {
      try {
        // Obtendo o token do localStorage
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error("Token não encontrado no localStorage");
        }

        const response = await axios.get('https://samp-teste.jelastic.saveincloud.net/backoffice/users/', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClientes(response.data);
      } catch (error) {
        console.error('Erro ao buscar os dados dos clientes:', error);
      }
    };

    fetchClientes();
  }, []);

  return (
    <div className="home-container">
      {/* HEADER */}
      <header className="home-header">
        <img src='./imagens/sampabank-logo.png' width={170} alt="SampaBank Logo" style={{ left: 30 }} />
        <h4 style={{ position: 'relative', right: '4%' }}></h4>
        <img src='./imagens/sino-icon.png' style={{ position: 'relative', left: -10 }} width={30} />
        <span style={{ position: 'relative', right: '1%', width: '7%', }}> 
          <a href='Login' style={{ color: 'white', fontWeight: 'bold' }}> Sair </a> 
        </span>
      </header>

      {/* BODY */}
      <body>
        {/* LISTA DE NAVEGAÇÃO */}
        <ul className='ListaNavegacao'>
          <li className='itemLista1'>
            <img src='./imagens/home-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/home'> Home</a>
          </li>
          <li className='itemLista2'>
            <img src='./imagens/extrato-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/extrato'> Extrato</a>
          </li>
          <li className='itemLista3' style={{ backgroundColor: '#75757536' }}>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href=''> Clientes</a>
          </li>
          <li className='itemLista4'>
            <img src='./imagens/funcionarios-icon.png' width={40} style={{ position: 'relative', right: 70, top: 18 }} />
            <a href='/funcionarios'> Funcionarios</a>
          </li>
        </ul>

        {/* ÁREA PRINCIPAL */}
        <div className='ContainerLista'>
          <table style={{ width: '80%', borderCollapse: 'collapse', color: 'white' }}>
            <thead>
              <tr>
                <th className='Titulo'>ID</th>
                <th className='Titulo'>Nome</th>
                <th className='Titulo'>CPF</th>
                <th className='Titulo'>Email</th>
                <th className='Titulo'>Saldo</th>
                <th className='Titulo'>Ações</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((cliente, index) => (
                <tr key={index}>
                  <td className='Titulo'>{cliente.mem_id}</td>
                  <td className='Titulo'>{cliente.firstname}</td>
                  <td className='Titulo'>{cliente.cpf}</td>
                  <td className='Titulo'>{cliente.email}</td>
                  <td className='Titulo'>
                    {(cliente.saldo / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                  </td>
                  <td className='Titulo'>
                    <img
                      src='./imagens/mais-icon.png'
                      width={25}
                      alt="Visualizar Extrato"
                      onClick={() => handleRedirect(cliente.mem_id)} // Redireciona com o mem_id
                      style={{ cursor: 'pointer' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* PAGINAÇÃO */}
          <div className="pagination">
            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
              &lt;
            </button>
            <span>{currentPage} / {totalPages}</span>
            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
              &gt;
            </button>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Clientes;
